<template>
  <entity-card
    :tabs="tabs"
    :tabs-items="tabsItems"
    :title="item.name"
    :image="image"
    :actions="actions"
    :loading="loading"
    :show-back-btn="canBack"
    @click:close="handleClose"
    @click:back="handleBack"
  >
    <template #header:status-bar>
      <div class="mr-2" :class="{ 'red--text': !item.enabled }">
        {{ item.enabled ? 'Enabled' : 'Disabled' }}
      </div>
      <div class="mr-2">
        {{ item.positionAltitude && item.positionAltitude.value }}
      </div>
    </template>
  </entity-card>
</template>

<script>
import EntityCard from '@/components/entity-card';
import {
  computed,
  onMounted,
  provide,
  toRefs,
  watch
} from '@vue/composition-api';

import { useLandmarkMenu } from '@/modules/landmarks/compositions/landmarkMenu';

import { useLandmark } from '@/modules/landmarks/compositions/landmark';
import { useMap } from '@/compositions/map';
import { useLandmarkObjects } from '../../compositions/landmarkObjects';
import useHistoryStack from '@/compositions/history-stack';

export default {
  name: 'LandmarkCard',
  components: {
    EntityCard
  },
  props: {
    landmarkId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { landmarkId } = toRefs(props);

    const {
      landmarks,
      markers: { setMarkers }
    } = useMap();

    const { hasPrevRoute: canBack } = useHistoryStack();

    const { item, load, loading } = useLandmark(landmarkId);
    const {
      objectList,
      load: loadObjects,
      loading: loadingLandmarkObjects
    } = useLandmarkObjects(landmarkId);

    const image = computed(() => item.value.url || '/img/object.png');

    provide('entity', item);
    provide('entityId', props.landmarkId);
    provide('landmarkObjects', objectList);
    provide('loadingLandmarkObjects', loadingLandmarkObjects);

    watch(
      () => item.value,
      value => {
        landmarks.setLandmarks([value]);
        landmarks.setMapToLandmarkCenter(landmarkId.value);
      }
    );

    watch(
      () => objectList.value,
      value => {
        setMarkers(value);
      }
    );

    onMounted(async () => {
      load();
      loadObjects();
    });

    const tabs = [
      {
        id: 'general',
        title: 'General'
      },
      {
        id: 'objects',
        title: 'Objects'
      },
      {
        id: 'geo',
        title: 'Geo'
      },
      // {
      //   id: 'media',
      //   title: 'Media'
      // },
      {
        id: 'notifications',
        title: 'Notifications'
      }
      // {
      //   id: 'access',
      //   title: 'Access'
      // }
    ];
    const tabsItems = [
      () => import('./general/index'),
      () => import('./objects/index'),
      {
        component: () => import('@/modules/objects/ui/object-card/geo/index'),
        props: { onlyGeotags: true, injectObjectKey: 'entity' }
      },
      {
        component: () =>
          import('@/modules/notifications/ui/RecentlyNotificationList'),
        props: { objectId: props.landmarkId }
      }
      // () => import('./access/index')
    ];

    const itemActions = computed(() => useLandmarkMenu({ entity: item.value }));

    // const handleClose = () => {
    //   const { landmarkId, ...params } = root.$route.params;
    //   const { st, ...query } = root.$route.query;
    //   root.$router.push({
    //     name: 'landmarks',
    //     params,
    //     query
    //   });
    // };
    const handleClose = () => {
      landmarks.unselectLandmark(landmarkId.value);
      emit('stack:close');
    };
    const handleBack = () => {
      emit('stack:back');
    };

    return {
      tabs,
      image,
      actions: itemActions,
      handleClose,
      handleBack,
      tabsItems,
      loading,
      item,
      canBack
    };
  }
};
</script>
