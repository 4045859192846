import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { flattenObject, SUB_EVENT_TYPES } from '@/provider/utils';
import landmarkObjectListSchema from '../api/landmark-object-list.graphql';

export function useLandmarkObjects(entityId) {
  const { subscribeToMore, result, load, loading } = useLazyQuery(
    landmarkObjectListSchema.fetch,
    {
      landmarkId: entityId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const objectList = useResult(result, [], data =>
    data.objectsToObjects.map(i => {
      const obj = flattenObject(i.object1);
      return {
        ...obj,
        color: obj.currentStateColor,
        iconSrc: obj.currentStateIcon,
        lat: obj.positionPoint?.lat,
        lon: obj.positionPoint?.lon
      };
    })
  );

  subscribeToMore({
    document: landmarkObjectListSchema.listenLandmarkObjectList,
    variables: {
      objectId: entityId
    },
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('landmark obejct list subscription');
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'ObjectsToObject': {
          return {
            objectsToObjects: [...previousResult.objectsToObjects, relatedNode]
          };
        }
      }
    }
  });

  return {
    load,
    objectList,
    loading
  };
}
